import React from 'react';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();

  // Handle logout and redirect to terminal
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('isAdmin');
    navigate('/terminal');
  };

  // Navigate back to the blog homepage
  const handleLogoClick = () => {
    navigate('/blog');
  };

  return (
    <div className="bg-black text-white p-4 flex justify-between items-center">
      {/* Logo on the top left */}
      <div
        className="text-xl font-bold cursor-pointer hover:text-gray-300"
        onClick={handleLogoClick}
      >
        drckgomz
      </div>

      {/* Log Out button on the top right */}
      <button
        onClick={handleLogout}
        className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg"
      >
        Log Out
      </button>
    </div>
  );
}

export default Navbar;
