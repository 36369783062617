// src/components/ManageUsers.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import toast

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Loading state
  const usersPerPage = 10;
  const navigate = useNavigate();
  const fetchCalled = useRef(false); // Ref to prevent multiple fetch calls

  // Fetch wrapper with token handling
  const fetchWithAuth = async (url, options) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication token missing. Please log in.');
      navigate('/login'); // Redirect to login
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });

    if (response.status === 403) {
      toast.error('Session expired. Please log in again.');
      navigate('/login'); // Redirect to login
    }

    return response;
  };

  // Fetch users from the backend
  const fetchUsers = async () => {
    setLoading(true); // Start loading
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/users`;

    try {
      const response = await fetchWithAuth(apiUrl, { method: 'GET' });
      if (!response.ok) throw new Error('Failed to fetch users');

      const data = await response.json();
      const sortedUsers = data.users.sort((a, b) =>
        a.username.localeCompare(b.username)
      );
      setUsers(sortedUsers);
      toast.success('Users fetched successfully!');
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('An error occurred while fetching users.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Add a new user with a temporary password
  const handleAddUser = async () => {
    if (!newUsername.trim()) {
      toast.error('Username cannot be empty.');
      return;
    }

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/users`;

    try {
      const response = await fetchWithAuth(apiUrl, {
        method: 'POST',
        body: JSON.stringify({
          username: newUsername.trim(),
          password: 'password123', // Default password
          isAdmin,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error adding user:', errorText);
        toast.error(`Error adding user: ${errorText}`);
        return;
      }

      const data = await response.json();
      console.log('User Added:', data);
      toast.success(`User "${data.user.username}" added successfully!`);
      fetchUsers(); // Refresh the user list
      setNewUsername('');
      setIsAdmin(false);
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error('An error occurred while adding the user.');
    }
  };

  // Remove a user
  const handleRemoveUser = async (userId, username) => {
    const confirmRemoval = window.confirm(`Are you sure you want to remove user "${username}"?`);
    if (!confirmRemoval) return;

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`;

    try {
      const response = await fetchWithAuth(apiUrl, { method: 'DELETE' });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error removing user:', errorText);
        toast.error(`Error removing user: ${errorText}`);
        return;
      }

      const data = await response.json();
      console.log('User Removed:', data);
      toast.success(`User "${data.user.username}" removed successfully!`);
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error('Error removing user:', error);
      toast.error('An error occurred while removing the user.');
    }
  };

  // Toggle admin status
  const handleToggleAdmin = async (userId, username, currentStatus) => {
    const action = currentStatus ? 'demote' : 'promote';
    const confirmAction = window.confirm(`Are you sure you want to ${action} "${username}" ${currentStatus ? 'from admin' : 'to admin'}?`);
    if (!confirmAction) return;

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`;

    try {
      const response = await fetchWithAuth(apiUrl, {
        method: 'PATCH',
        body: JSON.stringify({ isAdmin: !currentStatus }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error updating admin status:', errorText);
        toast.error(`Error updating admin status: ${errorText}`);
        return;
      }

      const data = await response.json();
      console.log('Admin Status Updated:', data);
      toast.success(`User "${data.user.username}" has been ${data.user.is_admin ? 'promoted to admin' : 'demoted from admin'}.`);
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error('Error updating admin status:', error);
      toast.error('An error occurred while updating admin status.');
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(users.length / usersPerPage);
  const currentUsers = users.slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (!fetchCalled.current) {
      fetchUsers();
      fetchCalled.current = true;
    }
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen py-10 bg-black text-white">
      <div className="w-full max-w-3xl p-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6 text-center">Manage Users</h2>

        {/* Add User Form */}
        <div className="mb-8">
          <input
            type="text"
            placeholder="Enter username"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleAddUser}
            className="w-full px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg transition duration-200"
          >
            Add User
          </button>
        </div>

        {/* User List with Pagination */}
        <div>
          <h3 className="text-2xl font-bold mb-4 text-center">Current Users</h3>
          {loading ? (
            <p className="text-center">Loading users...</p>
          ) : users.length === 0 ? (
            <p className="text-center">No users found.</p>
          ) : (
            <ul className="space-y-4">
              {currentUsers.map((user) => (
                <li key={user.id} className="flex justify-between items-center p-4 bg-gray-700 rounded-lg">
                  <div className="flex items-center space-x-4">
                    <span className="text-lg font-medium">{user.username}</span>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={user.is_admin}
                        onChange={() => handleToggleAdmin(user.id, user.username, user.is_admin)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-sm">Admin</span>
                    </label>
                  </div>
                  <button
                    onClick={() => handleRemoveUser(user.id, user.username)}
                    className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg transition duration-200"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}

          {/* Pagination Controls */}
          {users.length > usersPerPage && (
            <div className="flex justify-between mt-6">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg ${
                  currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Previous
              </button>
              <span className="text-lg">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg ${
                  currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Next
              </button>
            </div>
          )}
        </div>

        <button
          onClick={() => navigate('/admin')}
          className="w-full mt-8 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-200"
        >
          Back to Admin Panel
        </button>
      </div>
    </div>
  );
}

export default ManageUsers;
