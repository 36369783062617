import React from 'react';

function HomeFooter() {
  return (
    <div className="w-full p-4 bg-transparent text-center z-20 fixed bottom-0 sm:block">
      <div className="flex justify-center space-x-6">
        {/* YouTube Icon */}
        <a
          href="https://www.youtube.com/@drckgomz"
          className="text-white hover:opacity-60"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/youtube.png" alt="YouTube" className="w-8 h-8 sm:w-10 sm:h-10 object-contain hover:opacity-60" />
        </a>

        {/* X (Twitter) Icon */}
        <a
          href="https://x.com/DrckGomz"
          className="text-white hover:opacity-60"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/x.png" alt="X.com / Twitter" className="w-8 h-8 sm:w-10 sm:h-10 object-contain hover:opacity-60" />
        </a>

        {/* Instagram Icon */}
        <a
          href="https://www.instagram.com/drckgomz/"
          className="text-white hover:opacity-60"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/instagram.png" alt="Instagram" className="w-8 h-8 sm:w-10 sm:h-10 object-contain hover:opacity-60" />
        </a>
      </div>
    </div>
  );
}

export default HomeFooter;
