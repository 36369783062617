import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

function Admin() {
  const navigate = useNavigate();

  const handleBackToBlog = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    if (!isAdmin) {
      handleBackToBlog();
    }
  }, [handleBackToBlog]);

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />

      <div className="p-6 flex flex-col items-center">
        <h1 className="text-4xl font-bold mb-6">Admin Panel</h1>

        <div className="w-full max-w-3xl space-y-6">
          <button 
          onClick={() => navigate('/manage-users')}
          className="w-full px-6 py-4 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg">
            Manage Users
          </button>
          <button className="w-full px-6 py-4 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg">
            Create New Blog Post
          </button>
          <button className="w-full px-6 py-4 bg-yellow-600 hover:bg-yellow-700 text-white font-semibold rounded-lg">
            View All Blog Posts
          </button>
          <button className="w-full px-6 py-4 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg">
            Delete Blog Post
          </button>
        </div>

        <button
          onClick={handleBackToBlog}
          className="mt-8 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg"
        >
          Back to Blog
        </button>
      </div>
    </div>
  );
}

export default Admin;
