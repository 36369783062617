import React from 'react';

const HiddenVideo = ({ videoRef, setVideoFalse, videoSrc }) => (
  <div
    id="video-container"
    className="fixed inset-0 z-30 flex justify-center items-center bg-black bg-opacity-75 animate-fadeIn"
  >
    <div className="relative w-[90vw] h-[80vh] max-w-5xl">
      <video ref={videoRef} id="hidden-video" preload="auto" className="w-full h-full rounded-lg">
        <source src={videoSrc} type="video/mp4" />
      </video>

      <button
        onClick={setVideoFalse}
        className="absolute top-2 right-2 text-white bg-red-500 hover:bg-red-700 font-bold py-1 px-3 rounded"
      >
        X
      </button>
    </div>
  </div>
);

export default HiddenVideo;
