import React from 'react';

const Header = () => (
  <div className="absolute top-0 left-0 right-0 mt-10 flex justify-center items-center z-20">
    <a
      href="/"
      className="text-6xl font-bold text-white hover:opacity-80 tracking-wider"
    >
      DRCKGOMZ
    </a>
  </div>
);

export default Header;
