import React, { useRef, useEffect } from 'react';

function DotsCanvas() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let animationFrameId;

    const dotSpacing = 25; // Adjust spacing between dots
    const dotRadius = 1; // Adjust dot size
    const amplitude = 5; // Adjust wave amplitude
    const waveSpeed = 0.01; // Adjust wave speed

    const wavelength = 100;

    let dots = [];
    let time = 0;

    const generateDots = () => {
      // Set canvas size
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      dots = []; // Clear existing dots

      // Generate dots in a grid
      const columns = Math.floor(canvas.width / dotSpacing);
      const rows = Math.floor(canvas.height / dotSpacing);

      for (let row = 0; row <= rows; row++) {
        for (let col = 0; col <= columns; col++) {
          const x = col * dotSpacing;
          const y = row * dotSpacing;

          // Calculate offset based on position for wave effect
          const offset = (col + row) * 0.5; // Adjust multiplier for wave pattern

          dots.push({
            x,
            y,
            baseY: y,
            offset,
          });
        }
      }
    };

    generateDots();

    window.addEventListener('resize', generateDots);

    // Animation loop
    const render = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      dots.forEach((dot) => {
        // Update dot position for wave animation
        const wave = Math.sin((dot.x / wavelength) + time) * amplitude;
        const y = dot.y + wave;


        // Draw dot
        context.beginPath();
        context.arc(dot.x, y, dotRadius, 0, 2 * Math.PI);
        context.fillStyle = 'white';
        context.fill();
      });

      time += waveSpeed;

      animationFrameId = requestAnimationFrame(render);
    };

    render();

    // Clean up on unmount
    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', generateDots);
    };
  }, []);

  return (
    <div className="relative h-screen w-screen bg-black">
      <canvas ref={canvasRef} className="absolute top-0 left-0" />
    </div>
  );
}

export default DotsCanvas;
