import React, { useEffect } from 'react';
import './Home.css';
import DotsCanvas from '../components/DotsCanvas';
import HomeFooter from '../components/HomeFooter'; // Import the Footer component

function Home() {

  useEffect(() => {
    document.title = 'Home';

    // Disable scrolling on the Home page
    document.body.style.overflow = 'hidden';

    // Preload the Terminal page by invoking the dynamic import manually
    const preloadTerminal = () => {
      import('../pages/Terminal');
    };
    
    preloadTerminal();

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="relative h-screen w-screen flex flex-col justify-between overflow-hidden">
      {/* Transparent header over the canvas */}
      <header className="absolute top-0 w-full mt-2 sm:mt-1 p-4 flex justify-between items-center z-30">
        <div
          className="text-2xl sm:text-5xl text-white font-bold antialiased tracking-wide text-shadow-lg"
        >
          <a href="/" className="hover:opacity-60">DRCKGOMZ</a>
        </div>

        <div
          className="text-2xl sm:text-5xl text-white font-bold antialiased tracking-wide text-shadow-lg"
        >
          <a href="/terminal" className="hover:opacity-60">TERMINAL</a>
        </div>
      </header>

      {/* The canvas will fill the entire screen */}
      <div className="absolute inset-0 z-10">
        <DotsCanvas />
      </div>

      {/* Reusable Footer Component */}
      <HomeFooter />
    </div>
  );
}

export default Home;
