import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import BlogFooter from '../components/BlogFooter';

function Blog() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const loggedInUser = localStorage.getItem('username');

    console.log(`User: ${loggedInUser}`);
    if (!token) {
      navigate('/terminal');
    } else {
      setUsername(loggedInUser);
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col justify-between">
      <Navbar />
      <div className="p-6 flex flex-col items-center">
        <h1 className="text-3xl font-bold mb-4">Welcome to the Blog, {username}!</h1>
        <p className="text-lg mb-8">This is a protected route. You can only access this if you're logged in.</p>
        <div className="w-full max-w-3xl space-y-8">
          <p className="text-center text-lg">
            Feel free to explore posts, comment on your thoughts, and engage with the community!
          </p>
        </div>
      </div>
      <BlogFooter />
    </div>
  );
}

export default Blog;
