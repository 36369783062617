import React from 'react';

const TerminalInput = ({ input, setInput, handleSubmit, inputRef }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the default form submission behavior
      handleSubmit(event); // Calls the handleSubmit function passed as a prop
    }
  };

  return (
    <div id="input-line" className="flex items-center px-4 py-2">
      <span className="prompt text-prompt-color font-bold text-2xl mr-2">></span>
      <form onSubmit={handleSubmit} className="w-full">
        <input
          ref={inputRef}
          id="terminal-input"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown} // Add the onKeyDown event here
          className="w-full bg-transparent outline-none text-prompt-color border-none pl-2"
          autoFocus
          autoComplete="off"
        />
      </form>
    </div>
  );
};

export default TerminalInput;
