import React from 'react';

const VideoBackground = () => (
  <div id="video-background" className="absolute inset-0 z-0">
    <video
      id="background-video"
      playsInline
      autoPlay
      muted
      loop
      preload="auto"
      className="object-cover h-full w-full"
    >
      <source src="https://s3.amazonaws.com/www.drckgomz.com/background.webm" type="video/webm" />
    </video>
  </div>
);

export default VideoBackground;
