import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Terminal from './pages/Terminal';
import Blog from './pages/Blog';
import Admin from './pages/Admin';
import ManageUsers from './components/ManageUsers';

import ToastContainerComponent from './components/ToastContainerComponent'; // Import the ToastContainerComponent


import './App.css';

function App() {
  console.log("App component rendered");
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terminal" element={<Terminal />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/manage-users" element={<ManageUsers />} />
      </Routes>
    </Router>
    {/* Toast Container */}
    <ToastContainerComponent />
    </>
  );
}

export default App;
