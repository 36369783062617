import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BlogFooter() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  // Check if the user is an admin
  useEffect(() => {
    try {
      const adminStatus = localStorage.getItem('isAdmin') === 'true';
      setIsAdmin(adminStatus);
    } catch (error) {
      console.error('Error parsing isAdmin value:', error);
      setIsAdmin(false);
    }
  }, []);

  // Navigate to the Admin Panel
  const navigateToAdmin = () => {
    navigate('/admin');
  };

  return (
    <div className="bg-black text-white p-4 flex flex-col items-center">

      {/* Admin Panel button (only visible for admins) */}
      {isAdmin && (
        <button
          onClick={navigateToAdmin}
          className="mt-2 px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg"
        >
          Admin Panel
        </button>
      )}
    </div>
  );
}

export default BlogFooter;
